@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('./vendor/css/bootstrap.min.css');
@import url('./vendor/plugins/fontawesome/css/fontawesome.min.css');
@import url('./vendor/plugins/fontawesome/css/all.min.css');

/* @import url('./vendor/css/select2.min.css');
@import url('./vendor/plugins/simple-calendar/simple-calendar.css');
@import url('./vendor/plugins/datatables/datatables.min.css');
@import url('./vendor/plugins/slick/slick.css');
@import url('./vendor/plugins/slick/slick-theme.css'); */

@import url('./vendor/plugins/feather/feather.css');
@import url('./vendor/css/style.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dna-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 100vh;
  z-index: 999999;
}
.dna-loader circle:nth-child(1) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(3) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(5) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(7) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(9) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(11) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(13) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(15) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(17) {
  fill: #3d3d9e !important;
}
.dna-loader circle:nth-child(19) {
  fill: #3d3d9e !important;
}

.object-loader {
  margin-top: 50px;
  justify-content: center;
}
.custom-toast {
  color: #fff;
}
.custom-toast-success {
  background-color: #009efb;
}
.custom-toast-error {
  background-color: tomato;
}
div.react-date-picker__wrapper {
  border: 0;
}
button.react-calendar__tile.react-calendar__month-view__days__day {
  border: 0;
  background: #fff;
}
.react-calendar__navigation button {
  border: 0;
  background: #fff;
}
.react-date-picker__calendar.react-date-picker__calendar--open {
  margin-top: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background: #fff;
  z-index: 999;
}
.react-date-picker__calendar .react-calendar {
  padding: 10px;
  background: #fff;
}
.shimmer {
  position: relative;
  width: 100%;
  height: 28px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 8px;
}

.shimmer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  width: 32%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2)
  ); /* Adjust the opacity and color stops as desired */
  animation: shimmer 1s infinite;
}
.shimmer-circle {
  position: relative;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

.shimmer-circle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  width: 32%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2)
  ); /* Adjust the opacity and color stops as desired */
  animation: shimmer 1s infinite;
}
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}
.table-responsive {
  overflow: visible;
}
.table-responsive-with-dropdown > div {
  overflow: visible;
}
